
.problem-student-container {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  ::v-deep .el-table th.el-table__cell {
    background: rgba(245, 245, 251, 1);
  }
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .train-status {
    position: absolute !important;
    left: 62.5% !important;
  }
  .train-status-active {
    color: rgba(59, 180, 0, 1);
  }
  .train-container {
    position: relative;
    left: 38.5% !important;
  }
  .operation {
    position: relative;
    left: 36.7% !important;
  }
  .operation-active {
    color: rgba(17, 34, 216, 1);
    font-weight: bold;
  }
  .operation-normal {
    color: rgba(52, 52, 65, 1);
  }
  .pages-center {
    text-align: center;
  }
  ::v-deep .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link {
      font-weight: 400;
      color: #606266;
    }
  }
  .pages-center {
    text-align: center;
    margin-top: 20px;
  }
}
